import AccreditationRegister from "@/views/accredreestr/AccreditationRegister.vue";
import CertificateRegister from "@/views/accredreestr/CertificateRegister.vue";


export const routes = [
    {
        path: '/accredreestr',
        component: AccreditationRegister,
    },
    {
        path: '/accredreestr/details/:id',
        component: CertificateRegister,
        name: 'accredreestr.certificate.details',
        props: true,
    }
];
