<template>
  <div style="margin: 2% 20%">
    <div class="row" style="display: flex; align-items: center;">
      <div style="flex: 0 0 auto; margin-right: 10px;">
        <img src="../../assets/images/logo_old.gif" alt="" style="height: 100px; width: auto;">
      </div>
      <div style="flex: 1; text-align: center;">
        <h1 style="font-size: 32px; margin: 0;">
          Реестр организаций, осуществляющих образовательную деятельность по имеющим государственную аккредитацию образовательным программам
        </h1>
      </div>
    </div>

    <div style="text-align: center; margin: 20px 0; color: #008cba; font-size: 28px;">
      <p>Поиск организаций или индивидуальных предпринимателей, осуществляющих образовательную деятельность по имеющим государственную аккредитацию образовательным программам</p>
    </div>

    <hr style="margin: 20px 0;">


    <div style="text-align: left; font-size: 28px;">
      <p>Поиск по субъекту РФ</p>
      <div>
        <custom-select placeholder="Выберите субъект" v-model="filters.subject" :options="subjects" />
      </div>
    </div>

    <hr style="margin: 20px 0;">

    <div class="line">
      <div class="line__item">
        <div class="form-group">
          <label class="switch radio radio--m">
            <input
                type="radio"
                class="switch__input"
                name="participation"
                :value="1"
                v-model="selectedSearchType"
            >
            <span class="switch__cover radio__cover"></span>
            <span class="switch__label radio__label">Поиск по аккредитованной организации</span>
          </label>
        </div>
      </div>
      <div class="line__item">
        <div class="form-group">
          <label class="switch radio radio--m">
            <input
                type="radio"
                class="switch__input"
                name="participation"
                :value="2"
                v-model="selectedSearchType"
            >
            <span class="switch__cover radio__cover"></span>
            <span class="switch__label radio__label">Поиск по индивидуальному предпринимателю</span>
          </label>
        </div>
      </div>
    </div>

    <hr style="margin: 20px 0;">

    <div v-if="selectedSearchType === 1">
      <p>Вы выбрали поиск по аккредитованной организации.</p>
      <div class="col col--half">
        <input-text label="Наименование" v-model="filters.organization.name" />
      </div>
      <div class="col col--halffourth">
        <input-text label="ИНН" v-model="filters.organization.inn" />
      </div>
      <div class="col col--half">
        <input-text label="ОГРН" v-model="filters.organization.ogrn" />
      </div>
      <div class="col col--half">
        <input-text label="Место нахождения" v-model="filters.organization.place" />
      </div>
      <div class="col col--half">
        <custom-select label="Тип образовательной организации" placeholder="Выберите тип образовательной организации" v-model="filters.organization.type" :options="organizationTypes" />
      </div>
      <div class="col col--half">
        <custom-select label="Вид образовательной организации" placeholder="Выберите вид образовательной организации" v-model="filters.organization.studyType" :options="organizationKinds" />
      </div>
    </div>

    <div v-if="selectedSearchType === 2">
      <p>Вы выбрали поиск по индивидуальному предпринимателю.</p>
      <div class="col col--half">
        <input-text label="Фамилия" v-model="filters.person.lastname" />
      </div>
      <div class="col col--half">
        <input-text label="Имя" v-model="filters.person.firstname" />
      </div>
      <div class="col col--half">
        <input-text label="Отчество" v-model="filters.person.patronymic" />
      </div>
      <div class="col col--half">
        <input-text label="Место жительства" v-model="filters.person.place" />
      </div>
      <div class="col col--half">
        <input-text label="ЕГРИП" v-model="filters.person.egrip" />
      </div>
      <div class="col col--halffourth">
        <input-text label="ИНН" v-model="filters.person.inn" />
      </div>
    </div>

    <hr style="margin: 20px 0;">

    <p>Поиск по свидетельству</p>

    <div class="col col--half">
      <input-text label="Регистрационный номер" v-model="filters.certificate.registerNumber" />
    </div>
    <div class="col col--half">
      <input-text label="Серия бланка" v-model="filters.certificate.series" />
    </div>
    <div class="col col--half">
      <input-text label="Номер бланка" v-model="filters.certificate.number" />
    </div>
    <div class="row row--aife">
      <div class="col col--fourth">
        <input-text
            v-model="filters.certificate.startDate.startDate"
            label="Дата выдачи от"
            button="calendar"
            is-date
        />
        <input-text
            v-model="filters.certificate.startDate.endDate"
            label="Дата выдачи по"
            button="calendar"
            is-date
        />
      </div>
    </div>
    <div class="row row--aife">
      <div class="col col--fourth">
        <input-text
            v-model="filters.certificate.endDate.startDate"
            label="Дата выдачи от"
            button="calendar"
            is-date
        />
        <input-text
            v-model="filters.certificate.endDate.endDate"
            label="Дата выдачи по"
            button="calendar"
            is-date
        />
      </div>
    </div>
    <div class="col col--half">
      <custom-select label="Текущий статус свидетельства" placeholder="Не выбран" v-model="filters.certificate.status" :options="certificateStatuses" />
    </div>

    <hr style="margin: 20px 0;">

    <switch-input
        type="checkbox"
        size="m"
        label="Расширенный поиск"
        :model-value="filters.extended"
        @update:model-value="(value) => filters.extended = value"
    />

    <hr style="margin: 20px 0;">

    <div v-if="filters.extended">
      <p>Поиск по содержимому приложений</p>
      <div class="col col--half">
        <custom-select label="Статус приложения" placeholder="Не выбран" v-model="filters.supplements.status" :options="certificateSupplementStatuses" />
      </div>
      <div class="col col--half">
        <input-text label="Код образовательной программы" v-model="filters.supplements.code" />
      </div>
      <div class="col col--half">
        <input-text label="Наименование образовательной программы" v-model="filters.supplements.name" />
      </div>
      <div class="col col--half">
        <custom-select label="Уровень образования" placeholder="Не выбран" v-model="filters.supplements.level" :options="educationTypes" />
      </div>
    </div>

    <hr style="margin: 20px 0;">


    <div class="line__item">
      <custom-button :type="2" label="Очистить" @click="clearForm" />
      <custom-button :type="2" label="Поиск" @click="getRegisterTable" />
      <report-error-button-captcha style="text-align: right" :type="2" label="Сообщить ошибку" @click="modalIsOpen = true" />
    </div>

    <div class="tbl-wrap">
      <table class="tbl">
        <thead>
        <tr>
          <th>Наименование организации/ФИО индивидуального предпринимателя, указанные на бланке свидетельства</th>
          <th>Рег. номер</th>
          <th>Серия, номер бланка</th>
          <th>Дата выдачи</th>
          <th>Срок действия</th>
          <th>Статус</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="register in registers"
            :key="register.id"
            class="tbl-link"
            @click="redirectToEducationRegister(register.id)"
        >
          <td>{{ register.organization_name }}</td>
          <td>{{ register.register_number }}</td>
          <td>{{ register.serial_number }}</td>
          <td>{{ register.date_issue }}</td>
          <td>{{ register.date_end }}</td>
          <td>{{ register.name }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<style scoped>
.row {
  display: flex;
  align-items: center;
}
</style>

<script setup>

import {onMounted, ref} from "vue";
import { useRouter } from 'vue-router';
import {AccreditationRegisterRepository} from "@/repositories/AccreditationRegister/AccreditationRegister";
import CustomSelect from "@/components/CustomSelect.vue";
import InputText from "@/components/InputText.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import CustomButton from "@/components/CustomButton.vue";
import ReportErrorButtonCaptcha from "@/components/ReportErrorButtonCaptcha.vue";

const router = useRouter();
const filters = ref({
  subject: null,
  organization: {
    name: null,
    inn: null,
    ogrn: null,
    place: null,
    type: null,
    studyType: null,
  },
  person: {
    lastname: null,
    firstname: null,
    patronymic: null,
    place: null,
    egrip: null,
    inn: null,
  },
  certificate: {
    registerNumber: null,
    series: null,
    number: null,
    startDate: {
      startDate: null,
      endDate: null,
    },
    endDate: {
      startDate: null,
      endDate: null,
    },
    status: null
  },
  extended: false,
  supplements: {
    status: null,
    code: null,
    name: null,
    level: null
  }
});
const subjects = ref([]);
const organizationTypes = ref([]);
const organizationKinds = ref([]);
const certificateStatuses = ref([]);
const certificateSupplementStatuses = ref([]);
const educationTypes = ref([]);
const registers = ref([]);

const selectedSearchType = ref(1);

const redirectToEducationRegister = async (id) => {
  router.push(`/accredreestr/details/${id}`);
}

const fetchSubjects = async () => {
  try {
    subjects.value = await AccreditationRegisterRepository.getSubjects();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const fetchOrganizationTypes = async () => {
  try {
    organizationTypes.value = await AccreditationRegisterRepository.getOrganizationTypes();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const fetchOrganizationKinds = async () => {
  try {
    organizationKinds.value = await AccreditationRegisterRepository.getOrganizationKinds();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const fetchCertificateStatuses = async () => {
  try {
    certificateStatuses.value = await AccreditationRegisterRepository.getCertificateStatuses();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const fetchCertificateSupplementStatuses = async () => {
  try {
    certificateSupplementStatuses.value = await AccreditationRegisterRepository.getCertificateSupplementStatuses();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const fetchEducationTypes = async () => {
  try {
    educationTypes.value = await AccreditationRegisterRepository.getEducationTypes();
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};

const getRegisterTable = async () => {
  try {
    registers.value = await AccreditationRegisterRepository.getRegisterTable(filters.value);
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
  }
};


const clearForm = () => {
  filters.value = {
    subject: null,
    organization: {
      name: null,
      inn: null,
      ogrn: null,
      place: null,
      type: null,
      studyType: null,
    },
    person: {
      lastname: null,
      firstname: null,
      patronymic: null,
      place: null,
      egrip: null,
      inn: null,
    },
    certificate: {
      registerNumber: null,
      series: null,
      number: null,
      startDate: {
        startDate: null,
        endDate: null,
      },
      endDate: {
        startDate: null,
        endDate: null,
      },
      status: null,
    },
    extended: false,
    supplements: {
      status: null,
      code: null,
      name: null,
      level: null,
    },
  };
  selectedSearchType.value = 1;
};


onMounted(() => {
  fetchSubjects();
  fetchOrganizationTypes();
  fetchOrganizationKinds();
  fetchCertificateStatuses();
  fetchCertificateSupplementStatuses();
  fetchEducationTypes();
});
</script>
