<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="../../assets/images/logo_old.gif" alt="Логотип" class="logo-img" />
      </div>
      <div class="title">
        <h1>Сведения о выбранном свидетельстве</h1>
      </div>
    </div>

    <hr class="divider" />

    <div class="row">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th colspan="2">Сведения об образовательной организации или организации, осуществляющей обучение</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Субъект РФ</td>
          <td>{{ application.region }}</td>
        </tr>
        <tr>
          <td>Полное наименование образовательной организации или организации, осуществляющей обучение</td>
          <td>{{ application.organizationName }}</td>
        </tr>
        <tr>
          <td>Сокращённое наименование образовательной организации или организации, осуществляющей обучение</td>
          <td>{{ application.organizationShortName }}</td>
        </tr>
        <tr>
          <td>Место нахождения образовательной организации или организации, осуществляющей обучение</td>
          <td>{{ application.address }}</td>
        </tr>
        <tr>
          <td>ИНН</td>
          <td>{{ application.inn }}</td>
        </tr>
        <tr>
          <td>ОГРН</td>
          <td>{{ application.gosRegisterNumber }}</td>
        </tr>
        <tr>
          <th colspan="2">Общие сведения о государственной аккредитации</th>
        </tr>
        <tr>
          <td>Дата выдачи свидетельства</td>
          <td>{{ application.date }}</td>
        </tr>
        <tr>
          <td>Регистрационный номер свидетельства</td>
          <td>{{ application.registerNumber }}</td>
        </tr>
        <tr>
          <td>Серия, номер бланка свидетельства</td>
          <td>{{ application.seriaNumber }}</td>
        </tr>
        <tr>
          <td>Срок действия свидетельства</td>
          <td>{{ application.dateEnd }}</td>
        </tr>
        <tr>
          <td>Вид свидетельства (свидетельство, временное свидетельство)</td>
          <td>{{ application.type }}</td>
        </tr>
        <tr>
          <td>Текущий статус свидетельства</td>
          <td>{{ application.status }}</td>
        </tr>
        <tr>
          <td>Наименование аккредитационного органа, выдавшего свидетельство</td>
          <td>{{ application.controlOrgan }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <span data-target="additional-info" data-control="">Показать/Скрыть дополнительную информацию</span>
      <a onclick="excerpt(this)" class="button button1" :url="excerptUrl">Скачать реестровую выписку</a>
      <span class="excerpt-period-checkbox-acred">
        <input type="checkbox" name="set-period" id="set-period-checkbox" />
        <span>Указать период выборки</span>
      </span>
      <table class="table table-filled hidden" data-name="additional-info">
        <thead>
        <tr>
          <th>Тип решения</th>
          <th>Основание решения</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(decision, index) in decisions" :key="index">
          <td>{{ decision.type }}</td>
          <td>{{ decision.basis }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row bordered">
      <div class="paddings">
        <h3>Приложения к свидетельству</h3>
        <div class="form-horizontal">
          <div class="form-group">
            <span class="col-sm-6 control-label">Код образовательной программы:</span>
            <div class="col-sm-5">
              <input type="text" class="form-control" id="code" v-model="searchCode" />
            </div>
          </div>
          <div class="form-group">
            <span class="col-sm-6 control-label">Уровень образования:</span>
            <div class="col-sm-5">
              <select class="form-control" id="level" v-model="searchLevel">
                <option value="">Не выбрано</option>
                <option v-for="(level, index) in educationLevels" :key="index" :value="level">{{ level }}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-6 col-sm-5">
              <button class="btn btn-default" @click="searchPrograms">Искать</button>
            </div>
          </div>
        </div>
        <small>Для просмотра перечня аккредитованных образовательных программ перейдите по ссылке в приложение</small>
      </div>
      <table class="table table-filled table-hover">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование образовательной организации (филиала) или организации, осуществляющей обучение (филиала)/ФИО индивидуального предпринимателя</th>
          <th>Распорядительный акт о выдаче</th>
          <th>Текущий статус</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(supplement, index) in filteredSupplements" :key="supplement.id">
          <tr @click="toggleSupplement(supplement.id)" class="name_bold clickable">
            <td class="td_bord">{{ index + 1 }}</td>
            <td class="td_bord">{{ supplement.organizationName }}</td>
            <td class="td_bord">{{ supplement.order }}</td>
            <td class="td_bord">{{ supplement.status }}</td>
          </tr>
          <!-- Раскрывающаяся строка -->
          <tr v-if="expandedSupplements.includes(supplement.id)">
            <td colspan="4" class="supplement-details">
              <div class="supplement-section">
                <h4>Содержимое приложения</h4>
                <table class="table table-bordered">
                  <caption>Сведения о приложении</caption>
                  <tbody>
                  <tr>
                    <td>Серия, номер бланка приложения</td>
                    <td>{{ supplement.seriaNumber }}</td>
                  </tr>
                  <tr>
                    <td>Текущий статус приложения</td>
                    <td>{{ supplement.status }}</td>
                  </tr>
                  <tr>
                    <td>Дополнительные сведения</td>
                    <td>{{ supplement.additional }}</td>
                  </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-filled">
                  <caption>Сведения о принятых решениях по приложению</caption>
                  <thead>
                  <tr>
                    <th>Тип решения</th>
                    <th>Основание решения</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(decision, dIndex) in supplement.decisions" :key="dIndex">
                    <td>{{ decision.type }}</td>
                    <td>{{ decision.basis }}</td>
                  </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-filled cells-centered" :data-supplement-id="supplement.id">
                  <caption>Аккредитованные образовательные программы</caption>
                  <thead>
                  <tr>
                    <th>Код УГС</th>
                    <th>Наименование УГС</th>
                    <th>Код</th>
                    <th>Наименование</th>
                    <th>Уровень образования</th>
                    <th>Приостановление</th>
                    <th>Лишение</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(program, pIndex) in supplement.programs"
                      :key="pIndex"
                      :data-pcode="program.Code"
                      :data-plevel="program.educationLevel"
                      data-source="programms"
                  >
                    <td :data-column="program.UgsCode">{{ program.UgsCode }}</td>
                    <td :data-column="program.UgsName">{{ program.UgsName }}</td>
                    <td>{{ program.Code }}</td>
                    <td>{{ program.Name }}</td>
                    <td :data-column="program.educationLevel">{{ program.educationLevel }}</td>
                    <td :data-colored="program.suspended === 'Да'">{{ program.suspended }}</td>
                    <td :data-colored="program.canceled === 'Да'">{{ program.canceled }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { AccreditationRegisterRepository } from '@/repositories/AccreditationRegister/AccreditationRegister';
import { useRoute } from 'vue-router';

const application = ref({});
const supplements = ref([]);
const decisions = ref([]);
const searchCode = ref('');
const searchLevel = ref('');
const educationLevels = ref([]);
const expandedSupplements = ref([]);
const route = useRoute();
const id = ref(route.params.id);

onMounted(async () => {
  const data = await AccreditationRegisterRepository.find(id.value);
  application.value = data;
  supplements.value = data.supplements;
  decisions.value = data.decisions;
  educationLevels.value = data.educationLevels;
});

const filteredSupplements = computed(() => {
  return supplements.value.filter((supplement) => {
    return supplement.programs.some((program) => {
      const matchesCode = searchCode.value ? program.Code.includes(searchCode.value) : true;
      const matchesLevel = searchLevel.value ? program.educationLevel === searchLevel.value : true;
      return matchesCode && matchesLevel;
    });
  });
});

const toggleSupplement = (id) => {
  const index = expandedSupplements.value.indexOf(id);
  if (index === -1) {
    expandedSupplements.value = [id]; // Раскрываем только одну строку
  } else {
    expandedSupplements.value.splice(index, 1); // Скрываем строку
  }
};

const searchPrograms = () => {
  // Логика поиска уже реализована в computed свойстве
};
</script>

<style scoped>
.container {
  margin: 2% 10%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background: linear-gradient(90deg, #4a90e2, #5c9eff);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  flex: 0 0 auto;
  margin-right: 20px;
}

.logo-img {
  height: 100px;
  width: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.logo-img:hover {
  transform: scale(1.05);
}

.title {
  flex: 1;
  text-align: center;
}

.title h1 {
  font-size: 36px;
  margin: 0;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.divider {
  border: 0;
  height: 2px;
  background: linear-gradient(90deg, #4a90e2, #5c9eff);
  margin: 30px 0;
}

.row {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

.table th {
  background-color: #4a90e2;
  color: #fff;
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

.table caption {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: left;
  color: #4a90e2;
}

.clickable {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.clickable:hover {
  transform: scale(1.02);
}

.supplement-details {
  padding: 0;
}

.supplement-section {
  margin: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suspended {
  background-color: #f8bbd0;
}

.canceled {
  background-color: #f8bbd0;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button:hover {
  background-color: #357abd;
  transform: translateY(-2px);
}

.button1 {
  background-color: #5c9eff;
}

.button1:hover {
  background-color: #4a90e2;
}

.excerpt-period-checkbox-acred {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.excerpt-period-checkbox-acred input {
  margin-right: 10px;
}

.form-horizontal {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-group .control-label {
  flex: 1;
  font-weight: bold;
}

.form-group .form-control {
  flex: 2;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.form-group .form-control:focus {
  border-color: #4a90e2;
  outline: none;
}

.btn {
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  background-color: #357abd;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .container {
    margin: 2% 5%;
  }

  .title h1 {
    font-size: 28px;
  }

  .table th,
  .table td {
    padding: 10px;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-button {
    width: 100%;
  }

  .form-horizontal {
    flex-direction: column;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group .control-label {
    margin-bottom: 5px;
  }

  .form-group .form-control {
    width: 100%;
  }
}
</style>